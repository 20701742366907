<template>
    <div class="content">
        <div class="search-attr">
            <div>
                <a-input v-model="params.operName" size='small' style="width:150px;margin-right:5px" placeholder='操作者'></a-input>
                <a-select v-model="params.businessType" size='small' style="width:150px;" placeholder='操作类型' allowClear>
                    <a-select-option v-for="(item,index) of businessTypeList" :key="index" :value='item.value'>{{item.name}}</a-select-option>
                </a-select>
                 <a-range-picker style="width:200px;margin:5px;"  :placeholder='rangeHolder' size='small' @change="changeTime" />
                <a-button @click="search" size='small' style="margin-left:10px;">搜索</a-button>
            </div>
        </div>
        <div class="table-content" >
            <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
                
            </a-table>
        </div>
    </div>
</template>

<script>
export default {
    data(){
        return {
            columns:[
                {title:'操作者',dataIndex:'operName'},
                {title:'操作记录',dataIndex:'title'},
                {title:'方法名',dataIndex:'method',width:200,},
                {title:'操作时间',align:'center',dataIndex:'operTime'},
                {title:'操作路由',align:'center',dataIndex:'operUrl'},
                {title:'参数',align:'center',dataIndex:'operParam'},
                // {title:'业务类型',align:'center',dataIndex:'businessTypeName'},
            ],
            data:[],
            layout:{
                labelCol: { span: 4 },//标签占5份  共24份
                wrapperCol: { span:20 },
            },
            visible:false,
            params:{pageNum:1,pageSize:10,},
            pagination:{
                total:0,
                current:1,
                showTotal:(total)=>`共${total}条`,
            },
            loading:false,
            businessTypeList:[
                {name:'其他',value:0},
                {name:'新增',value:1},
                {name:'修改',value:2},
                {name:'删除',value:3},
                {name:'授权',value:4},
                {name:'导出',value:5},
                {name:'导入',value:6},
                {name:'强退',value:7},
                {name:'列表',value:-1},
                {name:'详细',value:-2},
            ],
            businessType:null,
            rangeHolder:['开始日期','结束日期'],
            times:[],
        }
    },
    created(){
        this.getData(this.params)
    },
    methods:{
        async getData(params){
            this.compoilerTimes(this.times)
            this.loading = true
            const res = await this.axios('/dq_admin/sys_oper_log/list',{params:params})
            this.loading = false
            const {records,total} = res.data
            this.data = records
            this.pagination.total = total
        },
        search(){
            this.params.pageNum = 1
            this.getData(this.params)
        },
        
        changePage(page){
            const {current,pageSize} = page
            this.pagination.current = current
            this.params.pageNum = current
            this.params.limit = pageSize
            this.getData(this.params)
        },
        changeTime(result){
            this.times = result
        },
        compoilerTimes(array){
            if(array&&array.length>0){
                array.forEach((ele,index) => {
                    if(index == 0){
                        this.params.startTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }else{
                        this.params.endTime = ele._d.toLocaleDateString().replace(/\//g,"-")
                    }
                });
            }
        }
    }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
    .table-content{
        flex:1;
        overflow-y: auto;
    }
}
.take-box{width:100%;max-height:700px;}
</style>